/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
      Case studies  Area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/


.case-studies-menu {
    background: $white;
}

.case-studies-area {
    padding-top: 120px;
    padding-bottom: 60px;
    @include mobile {
        padding-top: 135px;
    }
    @include tablet {
        padding-top: 155px;
        padding-bottom: 80px;
    }

    @include desktops {
        padding-top: 180px;
        padding-bottom: 130px;
    }
}

.case-studies-area-content {
    h2 {
        color: $haiti;
        font-size: 36px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: -1.98px;
        line-height: normal;
        margin-bottom: 15px;

        @include tablet {
            font-size: 55px;
        }

        @include desktops {
            font-size: 60px;
        }
    }

    p {
        color: $waterloo;
        font-size: 20px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: -0.66px;
        line-height: 34px;
    }
}



.case-studies-items {
    margin-bottom: -25px;
    padding-top: 40px;

    @include tablet {
        padding-top: 50px;
    }

    @include desktops {
        padding-top: 77px;
    }

    .col-lg-4 {
        margin-bottom: 25px;
    }
}

.case-studies-card {

    height: 100%;

    &__content {
        background: $white;
        padding: 30px 35px 30px 35px;

        h4 {
            
            font-size: 22px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: -0.73px;
            line-height: normal;
            margin-bottom: 25px;
        }
        a{
            color: $haiti;
            text-decoration: none;
        }

        p {
            color: $waterloo;
            font-size: 16px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: -0.53px;
            line-height: 28px;
            margin-bottom: 20px;
        }

        .btn {
            color: $primary;
            font-size: 16px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: -0.53px;
            line-height: normal;
            padding: 0;
            min-width: inherit;
            height: inherit;
            &:hover{
                color: $primary !important;
            }
        }
    }

}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
      Case study details  Area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.case-study-area {
    padding-top: 108px;
    @include mobile {
        padding-top: 135px;
    }
    @include tablet {
        padding-top: 153px;
    }

    @include desktops {
        padding-top: 215px;
        padding-bottom: 95px;
    }
}


.case-study-image {

    position: relative;


    @include desktops {
        position: absolute;
        top: 0;
        left: 0;
        background-size: cover;
        width: 42%;
        height: 100%;
        background-position: 100%;

        & img {
            display: none;
            ;
        }
    }

    @include large-desktops {
        width: 42%;
    }

    @include extra-large-desktops {
        width: 37%;
        background-position: 100%;
    }


}

.case-study-banner-content {
 padding-top: 40px;
 padding-bottom: 40px;
    @include tablet {
        padding-top: 50px;
        padding-bottom: 60px;
    }

    @include desktops {
        padding-top: 0;
        padding-right: 0px;
        padding-bottom: 0;
    }

    @include large-desktops {
        padding-right: 28px;
    }

    @include extra-large-desktops {
        padding-right: 135px;
    }

    h4 {
        color: #ff9653;
        font-size: 20px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: -0.66px;
        line-height: normal;
        margin-bottom: 35px;
        
    }

    h2 {
        color: $haiti;
        font-size: 36px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: -1.58px;
        line-height: 48px;
        @include mobile-lg {
            font-size: 40px;
            line-height: 52px;
        }
        @include tablet {
            font-size: 48px;
            line-height: 60px;
        }
    }
}

.time-line-area {
    margin: 40px -15px 0px -15px;

    @include tablet {
        margin: 50px -15px 0px -15px;
    }

    @include desktops {
        margin: 65px -15px 0px -15px;
    }
    .time-line{
        margin: 0px 15px 0px 15px;
    }

    .heading {
        h6 {
            color: $waterloo;
            font-size: 16px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: -0.53px;
            line-height: normal;
            margin-bottom: 25px;
        }
    }

    .content {
        span {
            margin-right: 10px;
        }

        li {
            color: $haiti;
            font-size: 16px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: -0.53px;
            line-height: normal;
            margin-bottom: 10px;
        }
    }
}

.case-study-content-area {
    padding-top: 50px;
    padding-bottom: 37px;

    @include tablet {
        padding-top: 60px;
        padding-bottom: 57px;
    }

    @include desktops {
        padding-top: 78px;
        padding-bottom: 107px;
    }

    .case-content-1 {
        p {
            color: $haiti;
            font-size: 16px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: -0.53px;
            line-height: 28px;
            margin-bottom: 30px;
        }
    }
}

.case-about-project {
    border-radius: 10px;
    background-color: $primary;
    padding: 32px 25px 18px 25px;
    margin: 15px 0px 45px 0px;

    @include tablet {
        padding: 32px 35px 18px 35px;
        margin: 30px 0px 65px 0px;
    }

    @include desktops {
        margin: 45px 25px 75px 25px;
    }

    h4 {
        color: $white;
        font-size: 24px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: -0.79px;
        line-height: normal;
        margin-bottom: 30px;
    }

    p {
        color: $white;
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: -0.53px;
        line-height: 28px
    }
}

.case-challenge-content {
    h4 {
        color: $haiti;
        font-size: 24px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: -0.79px;
        line-height: normal;
        margin-bottom: 35px;
    }

    p {
        color: $haiti;
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: -0.53px;
        line-height: 28px;
        margin-bottom: 25px;
    }
}

.solution-area {
    margin-top: 40px;

    @include tablet {
        margin-top: 50px;
    }

    @include desktops {
        margin-top: 67px;
    }

    h4 {
        color: $haiti;
        font-size: 24px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: -0.79px;
        line-height: normal;
        margin-bottom: 35px;
    }

    span {
        font-weight: 700;
        font-size: 20px;
        margin-right: 10px;
    }

    li {
        color: $haiti;
        font-size: 18px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: -0.59px;
        line-height: 40px;
        display: inline-flex;
    }
}

.case-result-area {
    margin-top: 40px;

    @include tablet {
        margin-top: 50px;
    }

    @include desktops {
        margin-top: 67px;
    }

    h4 {
        color: $haiti;
        font-size: 24px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: -0.79px;
        line-height: normal;
        margin-bottom: 35px;
    }

    p {
        color: $haiti;
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: -0.53px;
        line-height: 28px;
    }
}


.case-study-counter-area {
    margin-top: 50px;
    margin-bottom: -30px;

    .counter-item {
        margin-bottom: 30px;
        h2 {
            color: $haiti;
            font-size: 36px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: -2.97px;
            line-height: normal;

            @include tablet {
                font-size: 48px;
            }

            @include desktops {
                font-size: 90px;
            }
        }

        p {
            font-size: 20px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: -0.66px;
            line-height: normal;
            margin-top: 10px;
        }
    }
}

.other-case-study-area {
    padding-bottom: 60px;
    padding-top: 45px;

    @include tablet {
        padding-top: 65px;
        padding-bottom: 80px;
    }

    @include desktops {
        padding-top: 115px;
        padding-bottom: 130px;
    }

    h2 {
        margin-bottom: -12px;
    }
}